<template>
  <v-hover v-slot="{ hover }">
    <div
      :class="{
        hover,
        checked: $route.params.checklistId === checklist.uuid
      }"
      class="ChecklistSingleItem"
      :style="{ paddingLeft: paddingLeft + 'px' }"
      @click="pushRoute($router, checklistRoute)"
    >
      <ChecklistDoneStatus
        v-if="showDoneStatusBar"
        class="ChecklistSingleItem__checkbox"
        :done="indeterminateStatus"
      />
      <div
        v-if="checklist.children && checklist.children.length"
        class="ChecklistSingleItem__fold-button"
        :class="{
          'line-left': path.length > 1
        }"
      >
        <div
          v-if="checklist.children.length && !checklist.$folded"
          class="ChecklistSingleItem__line-bottom"
          :style="{ height: bottomLineHeight }"
        />
        <v-btn
          icon
          small
          @click.stop="$emit('fold-checklist')"
        >
          <v-icon color="#A9A7BF">
            {{ checklist.$folded ? 'mdi-menu-right' : 'mdi-menu-down' }}
          </v-icon>
        </v-btn>
      </div>

      <div
        v-else
        style="padding-left: 40px"
        class="ChecklistSingleItem__fold-button line-left-big"
      />
      <div
        class="ChecklistSingleItem__name"
        :class="{
          'first': path.length === 1,
          'inapplicable': checklist.done === 'inapplicable'
        }"
      >
        {{ checklist.name }}
        <v-icon
          v-if="checklist.description"
          small
          color="#66669938"
          class="ChecklistSingleItem__description ml-2"
        >
          mdi-message-text-outline
        </v-icon>
      </div>
      <ChecklistProgress
        v-if="checklist.children.length &&
          checklist.done !== 'inapplicable' &&
          showChecklistProgressBar"
        class="ChecklistSingleItem__progress"
        :class="{ hover, checked: $route.params.checklistId === checklist.uuid }"
        :checklist="checklist"
      />
    </div>
  </v-hover>
</template>

<script>
import { pushRoute } from '@/helpers'
import eachDeep from 'deepdash-es/eachDeep'
import ChecklistProgress from '@/components/ChecklistProgress'
import ChecklistDoneStatus from '@/components/ChecklistDoneStatus'

export default {
  name: 'ChecklistSingleItem',

  components: {
    ChecklistProgress,
    ChecklistDoneStatus,
  },

  props: {
    projectId: { type: String, required: true },
    checklist: { type: Object, required: true },
    path: { type: Array, required: true },
    showDoneStatusBar: { type: Boolean, default: true },
    showChecklistProgressBar: { type: Boolean, default: true },
    paddingLeft: { type: [String, Number], default: '85' },
    getRoute: {
      type: Function,
      default: ({ projectId, checklistId }) => ({
        name: 'ChecklistsListChecklist',
        params: { projectId, checklistId },
        query: { back: `/projects/${projectId}/checklists/${checklistId}` },
      }),
    },
  },

  data() {
    return {
      flattenChecklistUuid: [],
    }
  },

  computed: {
    bottomLineHeight() {
      this.flatChecklistNode(this.checklist.children, this.path)
      return this.flattenChecklistUuid.length * 48 - 14 + 'px'
    },

    checklistRoute() {
      const { projectId, checklist } = this
      return this.getRoute({ projectId, checklistId: checklist.uuid })
    },

    indeterminateStatus() {
      const { checklist } = this

      if (!checklist?.children?.length && !checklist?.connectedNodes?.length) return checklist.done
      if (checklist.done === 'done' || checklist.done === 'inapplicable') return checklist.done

      if (checklist?.children?.length) {
        const flattenCheckmark = []

        eachDeep(checklist.children, (value, key, checklist) => {
          if (key === 'uuid' && checklist.done !== 'inapplicable') {
            flattenCheckmark.push(checklist)
          }
        })

        if (flattenCheckmark.every(node => {
          return node.done === 'not_done' && node?.connectedNodes?.every(object => object.connectionDone === 'not_done')
        })) return 'not_done'

        if (flattenCheckmark.every(node => {
          return node.done === 'done'
        })) return 'done'

        if (flattenCheckmark.some(node => {
          return node.done === 'done' || node?.connectedNodes?.some(object => object.connectionDone === 'done')
        })) return 'indeterminate'
      }

      if (checklist?.connectedNodes?.length) {
        if (checklist?.connectedNodes?.every(object => object.connectionDone === 'not_done')) return 'not_done'
        if (checklist?.connectedNodes?.some(object => object.connectionDone === 'done')) return 'indeterminate'
      }

      return 'not_done'
    },
  },

  watch: {
    checklist: {
      handler(v) {
        if (v) this.flattenChecklistUuid = []
      },
      immediate: true,
    },
  },

  methods: {
    pushRoute,

    flatChecklistNode(checklist, path) {
      if (checklist?.$folded) {
        if (!this.flattenChecklistUuid.find(uuid => uuid === checklist.uuid)) {
          this.flattenChecklistUuid.push(checklist.uuid)
        }
      }

      if (Array.isArray(checklist)) {
        checklist.forEach((child, index) => {
          if (!this.flattenChecklistUuid.find(uuid => uuid === child.uuid)) {
            this.flattenChecklistUuid.push(child.uuid)
          }

          if (child?.children?.length && !child?.$folded && (checklist.length - 1 !== index || path?.length - 1 !== 0)) this.flatChecklistNode(child.children)
        })
      }

      if (!this.flattenChecklistUuid.find(uuid => uuid === checklist.uuid) && checklist.uuid) {
        this.flattenChecklistUuid.push(checklist.uuid)
      }
    },
  },
}
</script>

<style scoped lang="sass">
.ChecklistSingleItem
  display: flex
  align-items: center
  height: 48px
  background: #ffffff

  &.hover
    &:before
      content: ''
      position: absolute
      width: 120%
      height: inherit
      left: -20%
      background-color: #F8F8FD
      z-index: 1

  &.checked
    &:before
      content: ''
      position: absolute
      width: 125%
      height: inherit
      left: -20%
      background-color: #F8F8FD
      z-index: 1
      border: 1px solid rgba(0, 0, 0, 0.12)

  &__line-bottom
    position: absolute
    width: 1px
    top: 30px
    height: 100%
    background: rgba(0, 0, 0, 0.26)
    z-index: 4
    left: 15px

  &__fold-button
    position: relative
    z-index: 2

    &.line-left
      &:before
        content: ''
        position: absolute
        width: 10px
        top: 14px
        left: -4px
        height: 1px
        background: rgba(0, 0, 0, 0.26)
        z-index: 4

    &.line-left-big
      &:before
        content: ''
        position: absolute
        width: 30px
        top: 0
        left: -5px
        height: 1px
        background: rgba(0, 0, 0, 0.26)
        z-index: 4

  &__checkbox
    position: absolute
    left: 55px
    z-index: 2

    &.v-input--indeterminate
      &::v-deep
        .theme--light.v-icon
          color: #C1C1D2

  &__name
    font-size: 16px
    white-space: nowrap
    position: relative
    color: #38364D
    cursor: default
    z-index: 2

    &.first
      font-weight: 500

    &.inapplicable
      color: rgba(0, 0, 0, 0.38)

  &__progress
    height: 24px
    box-shadow: -21px 0px 14px 6px rgb(255 255 255)
    border-radius: 4px
    min-width: 280px
    z-index: 3

    &.hover
      box-shadow: -25px 0px 7px 2px rgb(248 248 253 / 95%)

    &.checked
      box-shadow: -25px 0px 7px 2px rgb(248 248 253 / 95%)

</style>
