<template>
  <div class="ChecklistDoneStatus">
    <v-tooltip
      v-if="done === 'done'"
      color="rgba(56, 54, 77, 1)"
      open-delay="200"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          color="#0288D1"
          v-bind="attrs"
          v-on="on"
        >
          mdi-check-circle-outline
        </v-icon>
      </template>
      <span>{{ tooltipName }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="done === 'not_done'"
      color="rgba(56, 54, 77, 1)"
      open-delay="200"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="done === 'not_done'"
          color="#8492A6"
          v-bind="attrs"
          v-on="on"
        >
          mdi-radiobox-blank
        </v-icon>
      </template>
      <span>{{ tooltipName }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="done === 'indeterminate'"
      color="rgba(56, 54, 77, 1)"
      open-delay="200"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="done === 'indeterminate'"
          color="#8492A6"
          v-bind="attrs"
          v-on="on"
        >
          mdi-minus-circle-outline
        </v-icon>
      </template>
      <span>{{ tooltipName }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="done === 'inapplicable'"
      color="rgba(56, 54, 77, 1)"
      open-delay="200"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="done === 'inapplicable'"
          color="#8492A6"
          v-bind="attrs"
          v-on="on"
        >
          mdi-close-circle-outline
        </v-icon>
      </template>
      <span>{{ tooltipName }}</span>
    </v-tooltip>

    <div
      v-if="!done"
    />
  </div>
</template>

<script>
export default {
  name: 'ChecklistDoneStatus',

  props: {
    done: { type: String, default: null },
  },

  computed: {
    tooltipName() {
      const { done } = this

      if (done === 'done') return this.$t('checklist.Checked')
      if (done === 'not_done') return this.$t('checklist.Unchecked')
      if (done === 'indeterminate') return this.$t('checklist.Undefined')
      if (done === 'inapplicable') return this.$t('checklist.Inapplicable')
      return this.$t('checklist.Undefined')
    },
  },
}
</script>

<style scoped>

</style>
