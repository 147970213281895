<template>
  <div class="ChecklistDefaultView">
    <Tree
      ref="checklistTree"
      :value="checklists"
      class="ChecklistDefaultView__checklists"
    >
      <div
        slot-scope="{ node: checklist, path }"
        class="ChecklistDefaultView__checklists-scope"
        @click="$emit('select-checklist', { checklist })"
      >
        <ChecklistSingleItem
          :project-id="projectId"
          :checklist="checklist"
          :path="path"
          :show-done-status-bar="showDoneStatusBar"
          :show-checklist-progress-bar="showChecklistProgressBar"
          :padding-left="paddingLeft"
          :get-route="getRoute"
          @fold-checklist="foldChecklist(checklist.uuid)"
        />
      </div>
    </Tree>
  </div>
</template>

<script>
import { Tree, Fold, Check } from 'he-tree-vue'
import ChecklistSingleItem from '@/components/ChecklistSingleItem'

export default {
  name: 'ChecklistDefaultView',

  components: {
    Tree: Tree.mixPlugins([Fold, Check]),
    ChecklistSingleItem,
  },

  props: {
    projectId: { type: String, required: true },

    // see `ChecklistSchema`; `store/schema:Checklist`
    checklists: { type: Array, required: true },

    // to style active checklist
    openChecklistId: { type: String, default: null },

    // list of selected rows for v-model
    value: { type: Array, default: () => [] },
    showDoneStatusBar: { type: Boolean, default: true },
    showChecklistProgressBar: { type: Boolean, default: true },
    paddingLeft: { type: [String, Number], default: '85' },
    getRoute: {
      type: Function,
      default: ({ projectId, checklistId }) => ({
        name: 'ChecklistsListChecklist',
        params: { projectId, checklistId },
        query: { back: `/projects/${projectId}/checklists/${checklistId}` },
      }),
    },
  },

  methods: {
    foldChecklist(checklistId) {
      this.$store.getters['checklist/get'](checklistId).$folded = !this.$store.getters['checklist/get'](checklistId).$folded
    },
  },
}
</script>

<style scoped lang="sass">
  .ChecklistDefaultView
    &__checklists
      &::v-deep
        .tree-branch
          position: relative
          overflow: hidden
</style>
