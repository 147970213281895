<template>
  <div
    class="ChecklistProgress"
    :style="{ justifyContent: justifyContent, background }"
  >
    <v-tooltip
      :disabled="isTooltipDisable"
      bottom
      color="#424266"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="ChecklistProgress__status"
            :style="{
              marginRight: marginRight + 'px',
              position: statusPosition,
              top: statusTop,
              right: statusRight,
            }"
          >
            {{ doneItems }}/{{ totalItems }} <span v-if="text">{{ text }}</span>
          </div>

          <v-icon
            v-if="doneItems === totalItems && checklist.done !== 'inapplicable' && totalItems !== 0"
            small
            color="#1066E5"
            class="ChecklistProgress__full-done-icon"
          >
            mdi-check
          </v-icon>
          <div
            class="ChecklistProgress__filling"
            :style="{ width: fillWidth + '%' }"
          />
        </div>
      </template>
      <span>{{ Math.round(fillWidth) || 0 }}%</span>
    </v-tooltip>
    <v-tooltip
      v-if="!isTooltipDisable"
      :disabled="isTooltipDisable"
      bottom
      color="#424266"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="ChecklistProgress__filling-empty"
          :style="{ width: '100%' }"
          v-on="on"
        />
      </template>
      <span>{{ 100 - Math.round(fillWidth) || 0 }}%</span>
    </v-tooltip>
  </div>
</template>

<script>
import eachDeep from 'deepdash-es/eachDeep'

export default {
  name: 'ChecklistProgress',

  props: {
    checklist: { type: [Object, Array], required: true },
    isObjViewActive: { type: Boolean, default: false },
    justifyContent: { type: String, default: 'center' },
    marginRight: { type: Number, default: 0 },
    text: { type: String, default: null },
    background: { type: String, default: '#FAFAFA' },
    isTooltipDisable: { type: Boolean, default: true },
    statusPosition: { type: String, default: 'relative' },
    statusTop: { type: String, default: 'inherit' },
    statusRight: { type: String, default: 'inherit' },
  },

  computed: {
    fillWidth() {
      const { totalItems, doneItems } = this

      let width = 2
      if (doneItems === 0) return width

      width = doneItems * 100 / totalItems
      return width
    },

    totalItems() {
      const { checklist } = this
      let total = 0

      eachDeep(checklist.children || checklist, (value, key, parent) => {
        if (key === 'done' &&
          !parent.children.length) total++
      })

      return Number(total)
    },

    doneItems() {
      const { checklist, isObjViewActive } = this
      let total = 0

      eachDeep(checklist.children || checklist, (value, key, parent) => {
        if (isObjViewActive &&
          key === 'done' &&
          value[0] === 'checklist' &&
          (parent.connectionDone === 'done' || parent.connectionDone === 'inapplicable') &&
          !parent.children.length
        ) total++

        if (!isObjViewActive &&
          key === 'done' &&
          (value === 'done' || value === 'inapplicable') &&
          !parent.children.length
        ) total++
      })

      return Number(total)
    },
  },
}
</script>

<style lang="sass" scoped>
.ChecklistProgress
  height: inherit
  display: flex
  align-items: center
  padding: 0 20px 0 30px
  font-size: 14px
  color: rgba(0, 0, 0, 0.38)
  position: absolute
  overflow: hidden
  right: 25px
  z-index: 3

  &__users
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    right: 20%
    z-index: 3

  &__avatar
    position: absolute
    border: 2px solid #fff

  &__status
    color: rgba(0, 0, 0, 0.38)
    position: relative
    z-index: 2

  &__filling
    position: absolute
    height: 100%
    min-width: 2%
    left: 0
    top: 0
    background: #BBDEFB
    z-index: 1
    transition: width 1s

    &-empty
      background: #ffffff

  &__full-done-icon
    position: absolute
    right: 10px
    top: 3px
    z-index: 2
</style>
