var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"ChecklistSingleItem",class:{
      hover,
      checked: _vm.$route.params.checklistId === _vm.checklist.uuid
    },style:({ paddingLeft: _vm.paddingLeft + 'px' }),on:{"click":function($event){return _vm.pushRoute(_vm.$router, _vm.checklistRoute)}}},[(_vm.showDoneStatusBar)?_c('ChecklistDoneStatus',{staticClass:"ChecklistSingleItem__checkbox",attrs:{"done":_vm.indeterminateStatus}}):_vm._e(),(_vm.checklist.children && _vm.checklist.children.length)?_c('div',{staticClass:"ChecklistSingleItem__fold-button",class:{
        'line-left': _vm.path.length > 1
      }},[(_vm.checklist.children.length && !_vm.checklist.$folded)?_c('div',{staticClass:"ChecklistSingleItem__line-bottom",style:({ height: _vm.bottomLineHeight })}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('fold-checklist')}}},[_c('v-icon',{attrs:{"color":"#A9A7BF"}},[_vm._v(" "+_vm._s(_vm.checklist.$folded ? 'mdi-menu-right' : 'mdi-menu-down')+" ")])],1)],1):_c('div',{staticClass:"ChecklistSingleItem__fold-button line-left-big",staticStyle:{"padding-left":"40px"}}),_c('div',{staticClass:"ChecklistSingleItem__name",class:{
        'first': _vm.path.length === 1,
        'inapplicable': _vm.checklist.done === 'inapplicable'
      }},[_vm._v(" "+_vm._s(_vm.checklist.name)+" "),(_vm.checklist.description)?_c('v-icon',{staticClass:"ChecklistSingleItem__description ml-2",attrs:{"small":"","color":"#66669938"}},[_vm._v(" mdi-message-text-outline ")]):_vm._e()],1),(_vm.checklist.children.length &&
        _vm.checklist.done !== 'inapplicable' &&
        _vm.showChecklistProgressBar)?_c('ChecklistProgress',{staticClass:"ChecklistSingleItem__progress",class:{ hover, checked: _vm.$route.params.checklistId === _vm.checklist.uuid },attrs:{"checklist":_vm.checklist}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }